<template>
  <div>
    <webcam-photo
      ref="webcam"
      @snapShot="onValueChanged"
    />
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >
          <!-- Header -->
          <div
            style="padding-top: 85px"
            class="d-flex content-sidebar-header"
          >
            <feather-icon
              class="cursor-pointer m-1"
              icon="ArrowLeftIcon"
              size="25"
              @click="hide"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center px-2 py-1">
            <h5 class="mb-0">
              Adicionar Novo Paciente
            </h5>
          </div>

          <div>
            <validation-observer
              #default="{invalid}"
              ref="refFormObserver"
            >
              <b-form
                class="p-2"
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Patient Profile Picture -->

                  <b-col
                    class="text-center"
                    cols="12"
                    md="4"
                  >
                    <div
                      class="mt-1 d-flex align-self-center justify-content-center cursor-pointer"
                      @mouseover="mouseOver = true"
                      @mouseleave="mouseOver = false"
                      @click="loadCamera"
                    >
                      <b-overlay
                        :show="showOverlay"
                        rounded="sm"
                        no-fade
                      >
                        <b-avatar
                          rounded="lg"
                          variant="light-primary"
                          size="125"
                        >
                          <feather-icon
                            v-if="!userData.photo || !userData.photo.url"
                            :icon="mouseOver === false ? 'UserIcon' : mouseOver === null ? 'UserIcon' : 'CameraIcon'"
                            :size="mouseOver === false ? '85' : mouseOver === null ? '85' : '60'"
                          />

                          <div
                            v-if="(userData.photo && userData.photo.url) || userData.photo"
                            class="position-relative d-flex justify-content-center align-items-center"
                          >
                            <img
                              class="rounded"
                              style="width: 135px; height: 135px;"
                              :src="(userData.photo && userData.photo.url) ? userData.photo.url : userData.photo"
                              :alt="userData.name"
                            >
                            <feather-icon
                              v-if="mouseOver === true"
                              icon="CameraIcon"
                              size="60"
                              class="position-absolute"
                            />
                          </div>
                        </b-avatar>
                      </b-overlay>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    md="8"
                    align-self="end"
                  >
                    <b-row>

                      <!--NAME-->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nome completo"
                          rules="required"
                          immediate
                        >
                          <b-form-group
                            label="Nome Completo"
                            label-for="patient-name-add"
                          >
                            <b-form-input
                              id="patient-name-add"
                              v-model="userData.name"
                              autofocus
                              :state="errors.length > 0 ? false : null"
                              trim
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!--CALL ME-->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          name="Call Me"
                        >
                          <b-form-group
                            label="Como deseja ser chamado"
                            label-for="nickname-add"
                          >
                            <b-form-input
                              id="nickname-add"
                              v-model="userData.nickname"
                              autofocus
                              trim
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!--EMAIL-->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="email"
                        >
                          <b-form-group
                            label="Email"
                            label-for="email"
                          >
                            <b-form-input
                              id="email"
                              v-model="userData.email"
                              autofocus
                              :state="errors.length > 0 ? false : null"
                              trim
                            />

                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!--CPF/CNPJ-->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="CPF"
                          :rules="(age(userData.birthday) > 17 ? ($store.getters['store-patient/patient'].identification1 !== userData.identification1 ? `required|cpfCnpj|checkDoubleCpf:${userData.id},PATIENT` : 'required|cpfCnpj') : ($store.getters['store-patient/patient'].identification1 !== userData.identification1 ? `cpfCnpj|checkDoubleCpf:${userData.id},PATIENT` : 'cpfCnpj'))"
                          immediate
                        >
                          <b-form-group
                            label="CPF"
                            label-for="cpf-add"
                          >
                            <b-form-input
                              id="cpf-add"
                              v-model="userData.identification1"
                              v-mask="['###.###.###-##']"
                              autofocus
                              :state="errors.length > 0 ? false : null"
                              trim
                            />

                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <!--BIRTHDAY-->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <!-- :rules="userData.responsible && !userData.responsible.name && !userData.responsible.identification1 ? `responsibleRequired:${userData.birthday}` : ''" -->
                    <validation-provider
                      #default="{ errors }"
                      name="birthday"
                      rules="birthDate"
                    >
                      <b-form-group>
                        <label for="birthday-add">Nascimento</label>
                        <small
                          class="text-muted ml-1"
                        ><i>{{ userData.birthday | age() }} anos.</i></small>
                        <b-form-input
                          id="birthday-add"
                          v-model="userData.birthday"
                          type="date"
                          autofocus
                          :state="errors.length > 0 ? false : null"
                          trim
                        />

                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!--PHONE NUMBER 1-->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      name="celular principal"
                    >
                      <b-form-group
                        label="Celular - Principal"
                        label-for="new-patient-phone-number"
                      >
                        <b-form-input
                          id="new-patient-phone-number"
                          v-model="userData.phoneNumber"
                          v-mask="['(##) ####-#####', '(##) # ####-####']"
                          autofocus
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!--PHONE NUMBER 2-->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      name="celular"
                    >
                      <b-form-group
                        label="Celular"
                        label-for="new-patient-second-phone-number"
                      >
                        <b-form-input
                          id="new-patient-second-phone-number"
                          v-model="userData.secondPhoneNumber"
                          v-mask="['(##) ####-#####', '(##) # ####-####']"
                          autofocus
                          trim
                        />

                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!--PHONE NUMBER 3-->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      name="fixo"
                    >
                      <b-form-group
                        label="Fixo"
                        label-for="new-patient-third-phone-number"
                      >
                        <b-form-input
                          id="new-patient-third-phone-number"
                          v-model="userData.thirdPhoneNumber"
                          v-mask="['(##) ####-#####', '(##) # ####-####']"
                          autofocus
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!--HOW DID YOU FIND US-->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Como você nos encontrou?"
                      label-for="how_did_you_find_us"
                    >
                      <v-select
                        id="id-how_did_you_find_us"
                        v-model="userData.how_did_you_find_us"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :reduce="val => val.value"
                        :clearable="true"
                        :options="howDidYouFindUsOptions"
                        label="text"
                        placeholder="Selecione"
                      />

                    </b-form-group>
                  </b-col>

                </b-row>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="invalid"
                  >
                    Adicionar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    :disabled="invalid"
                    @click.prevent="onSubmit(true)"
                  >
                    Adicionar e ir ao Prontuário
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancelar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, cpfCnpj, birthDate,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import WebcamPhoto from '@/views/components/webcam-photo/WebcamPhoto.vue'
import router from '@/router'

import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    WebcamPhoto,
    BOverlay,
    BAvatar,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    mask,
  },

  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },

  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const mouseOver = ref(null)
    const showOverlay = ref(false)

    const blankUserData = {
      photo: null,
      name: null,
      nickname: null,
      email: null,
      identification1: null,
      phoneNumber: null,
      secondPhoneNumber: null,
      thirdPhoneNumber: null,
      how_did_you_find_us: null,
      responsible: {},
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    // eslint-disable-next-line global-require
    const noUserPhoto = require('@/assets/images/avatars/no-picture.png')

    const howDidYouFindUsOptions = ref([
      { value: 'GOOGLE', text: 'GOOGLE' },
      { value: 'FACEBOOK', text: 'FACEBOOK' },
      { value: 'INSTAGRAM', text: 'INSTAGRAM' },
      { value: 'INDICAÇÃO', text: 'INDICAÇÃO' },
      { value: 'FACHADA', text: 'FACHADA' },
      { value: 'PANFLHETO', text: 'PANFLHETO' },
      { value: 'RÁDIO', text: 'RÁDIO' },
      { value: 'TV', text: 'TV' },
      { value: 'OUTRO', text: 'OUTRO' },
    ])

    const treatmentTypeOptions = ref([
      { value: 'AVALIAÇÃO ORTODÔNTICA', text: 'AVALIAÇÃO ORTODÔNTICA' },
      { value: 'AVALIAÇÃO CLÍNICA', text: 'AVALIAÇÃO CLÍNICA' },
      { value: 'OUTRO', text: 'OUTRO' },
    ])

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const age = value => {
      if (value && value.length === 10 && moment(value, 'YYYY-MM-DD').isValid()) {
        return moment().diff(value, 'years') || 0
      }
      return 0
    }

    const webcam = ref(null)
    const loadCamera = () => {
      webcam.value.loadCamera()
    }

    const onValueChanged = value => {
      userData.value.photo = value.base_img
    }

    const onSubmit = async (redirect = false) => {
      try {
        showOverlay.value = true
        const response = await store.dispatch('store-patient/add', userData.value)
        emit('update:is-add-new-user-sidebar-active', false)
        emit('showToast')
        showOverlay.value = false
        if (redirect === true) router.push({ name: 'apps-patients-edit', params: { id: response.data.id } })
        else emit('refetch-data')
      } catch (error) {
        showOverlay.value = false
        if (error.response.status === 412) {
          emit('showToast', 'warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          emit('showToast', 'danger', 'AlertCircleIcon', 'Atenção', error.message)
        }
      }
    }

    return {
      userData,
      refFormObserver,
      noUserPhoto,
      howDidYouFindUsOptions,
      treatmentTypeOptions,
      mouseOver,
      showOverlay,
      required,
      alphaNum,
      email,
      cpfCnpj,
      birthDate,
      webcam,

      onSubmit,
      loadCamera,
      onValueChanged,
      age,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
  }

.retracted-menu {
  width: calc(100% - 108px);
  }

.no-xl {
  width: calc(100% - 28px);
}
</style>
